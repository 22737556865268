import { createAsyncThunk } from '@reduxjs/toolkit'
import { api } from 'api/fx/api'
import { Components, Paths } from 'api/fx/generated/client'
import { AsyncThunkDataType } from 'store/common'
import { AsyncThunkActionType } from 'store/store'

type QueryParameters = Paths.BankAccountFrontofficeControllerFindFxBankAccount.QueryParameters
type FxBankAccountResponse = Components.Schemas.FxBankAccountResponse

export const fxBankAccountGet: AsyncThunkActionType<
	FxBankAccountResponse,
	AsyncThunkDataType<QueryParameters, null, 'params'>
> = createAsyncThunk('fxBankAccountGet', async (data, thunkAPI) => {
	const { rejectWithValue } = thunkAPI

	try {
		const response = await api.BankAccountFrontofficeController_findFxBankAccount(data.params, null, data?.config)

		return response.data as FxBankAccountResponse
	} catch (err: any) {
		return rejectWithValue(err.response?.data?.message)
	}
})
