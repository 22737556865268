export type KYC_STATUS = 'VERIFIED' | 'PENDING_KYC' | 'BLOCKED' | 'CLOSED' | 'UNKNOWN'

export const kycFriendlyName: { [k in KYC_STATUS]: string } = {
	PENDING_KYC: 'KYC pending',
	VERIFIED: 'Verified',
	BLOCKED: 'Blocked',
	CLOSED: 'Closed',
	UNKNOWN: 'Unknown',
}

export type CLIENT_STATUS = KYC_STATUS

export const clientStatuses: CLIENT_STATUS[] = ['PENDING_KYC', 'VERIFIED']
export const adminStatuses: CLIENT_STATUS[] = ['PENDING_KYC']
export const completed: CLIENT_STATUS[] = ['VERIFIED']

export const getStatus = (kycStatus?: CLIENT_STATUS): CLIENT_STATUS => kycStatus || 'PENDING_KYC'
export const getStatusFriendlyName = (status?: CLIENT_STATUS): string => kycFriendlyName[status as CLIENT_STATUS] || ''
