import { CheckCircleFilled, CloseCircleOutlined, SyncOutlined } from '@ant-design/icons'
import { CLIENT_STATUS, completed } from 'modules/helpers/clientStatuses/clientStatuses'
import { themeColor } from 'modules/styles/themeStyles'
import { FC } from 'react'
import styled from 'styled-components'

const SyncOutlinedStyled = styled(SyncOutlined)`
	color: ${themeColor.pink};
	vertical-align: middle;
`
const CloseCircleOutlinedStyled = styled(CloseCircleOutlined)`
	color: ${themeColor.pink};
	vertical-align: middle;
`
const CheckCircleFilledStyled = styled(CheckCircleFilled)`
	color: ${themeColor.green};
	vertical-align: middle;
`

export const ClientStatusIcon: FC<{ status?: CLIENT_STATUS; showSync?: boolean }> = ({ status, showSync = false }) => {
	if (status && completed.includes(status)) {
		return <CheckCircleFilledStyled />
	} else if (status === 'UNKNOWN') {
		return <CloseCircleOutlinedStyled />
	}

	return showSync ? <SyncOutlinedStyled /> : null
}
